import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiRoutes } from "../config/api-routes";
import "rxjs/Rx";
import { Storage } from "../utilities/storage";
import { Location } from "../../../../node_modules/@angular/common";
import { Helper } from "../utilities/helpers";

@Injectable()
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private apiRoutes: ApiRoutes,
    public storage: Storage,
    public location: Location
  ) {}

  login(credentials) {
    return this.http
      .post(this.apiRoutes.loginUrl(), {
        email: Helper.getObjProp(credentials, "email"),
        password: Helper.getObjProp(credentials, "password"),
        authType: "client",
      })
      .map((res: any) => {
        this.storage.set("currentUser", {
          id: res.data.id,
          showTutorial: true,
          slug: res.data.slug,
          profilePhoto: res.data.profile_photo_url,
          username: res.data.full_name,
          token: res.data.token,
          email: res.data.email,
          phone: res.data.mobile_number,
          dob: res.data.dob_formatted,
          package: res.data.owner.package.name,
          status: res.data.owner.status,
          total_programs: res.data.owner.package_programs_count,
          enrolment_date: res.data.owner.enrolment_date,
          enrolment_centre: res.data.owner.enrolment_centre,
          amount_paid: res.data.owner.amount_paid,
          balance: res.data.owner.balance,
          packageId: res.data.owner.package.id,
        });
        this.storage.set("currentStudent", {
          id: res.data.owner.id,
          enrolment: res.data.owner.enrolment,
        });
        return res;
      });
  }

  logout() {
    let formData = {
      fcm_token: this.storage.get("studentFcmToken"),
    };
    let currentUser = this.storage.get("currentUser");
    let headers = new HttpHeaders({
      Authorization: `Bearer ${currentUser.token}`,
    });
    this.storage.clear();
    return this.http
      .post(this.apiRoutes.getLogoutUrl, formData, { headers })
      .map((res: any) => {
        return true;
      });
  }

  signup(passwordData: any) {
    return this.http
      .post(this.apiRoutes.getSetPasswordUrl(), passwordData)
      .map((res: any) => {
        this.storage.set("currentUser", {
          id: res.data.id,
          showTutorial: true,
          slug: res.data.slug,
          profilePhoto: res.data.profile_photo_url,
          username: res.data.full_name,
          token: res.data.token,
          email: res.data.email,
          phone: res.data.mobile_number,
          dob: res.data.dob_formatted,
          package: res.data.owner.package.name,
          status: res.data.owner.status,
          total_programs: res.data.owner.package_programs_count,
          enrolment_date: res.data.owner.enrolment_date,
          enrolment_centre: res.data.owner.enrolment_centre,
          amount_paid: res.data.owner.amount_paid,
          balance: res.data.owner.balance,
        });
        this.storage.set("currentStudent", { id: res.data.owner.id });
        return res;
      });
  }

  resetPassword(resetPasswordData: any) {
    return this.http
      .post(this.apiRoutes.getResetPasswordUrl(), resetPasswordData)
      .map((res: any) => {
        return res;
      });
  }

  forgotPassword(formData) {
    return this.http
      .post(this.apiRoutes.getForgotPasswordUrl(), formData)
      .map((res: any) => {
        return res;
      });
  }

  get authUser() {
    return this.storage.get("currentUser");
  }

  // Get Auth Token
  get getAuthToken() {
    let authUser = this.storage.get("currentUser");
    let token = authUser.token;
    return "Bearer " + token;
  }
}
