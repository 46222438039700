import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Layouts
import { FullLayoutComponent } from "./core/components/layouts/full-layout.component";
import { SimpleLayoutComponent } from "./core/components/layouts/simple-layout.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { ErrorPageComponent } from "./core/components/error-page/error-page.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "pages/login",
    pathMatch: "full",
  },
  {
    path: "",
    component: FullLayoutComponent,
    data: {
      title: "Home",
    },
    children: [
      {
        path: "confirm-details",
        loadChildren:
          "./core/components/confirm-details/confirm-details.module#ConfirmDetailsModule",
      },
      {
        path: "profile",
        loadChildren: "./core/components/profile/profile.module#ProfileModule",
      },
      {
        path: "enrollment-form",
        loadChildren:
          "./core/components/enrollment-form/enrollment-form.module#EnrollmentFormModule",
      },
      {
        path: "enrollment-submitted",
        loadChildren:
          "./core/components/enrollment-submitted/enrollment-submitted.module#EnrollmentSubmittedModule",
      },
      {
        path: "counsellor-assigned",
        loadChildren:
          "./core/components/counsellor-assigned/counsellor-assigned.module#CounsellorAssignedModule",
      },
      {
        path: "mentor-assigned",
        loadChildren:
          "./core/components/mentor-assigned/mentor-assigned.module#MentorAssignedModule",
      },
      {
        path: "error",
        component: ErrorPageComponent,
      },
      {
        path: "colleges",
        loadChildren:
          "./core/components/colleges/colleges.module#CollegesModule",
      },
      {
        path: "roadmap",
        loadChildren: "./core/components/roadmap/roadmap.module#RoadmapModule",
      },
      {
        path: "notifications",
        loadChildren:
          "./core/components/notifications/notifications.module#NotificationsModule",
      },
      {
        path: "chat",
        loadChildren: "./core/components/chat/chat.module#ChatModule",
      },
      {
        path: "roadmap",
        loadChildren: "./core/components/roadmap/roadmap.module#RoadmapModule",
      },
      {
        path: "notifications",
        loadChildren:
          "./core/components/notifications/notifications.module#NotificationsModule",
      },
      {
        path: "tutorial",
        loadChildren:
          "./core/components/tutorial/tutorial.module#TutorialModule",
      },
      {
        path: "individual-college/:id",
        loadChildren:
          "./core/components/individual-college/individual-college.module#IndividualCollegeModule",
      },
      {
        path: "recommendation",
        loadChildren:
          "./core/components/recommendation/recommendation.module#RecommendationModule",
      },
      {
        path: "appointments",
        loadChildren:
          "./core/components/appointments/appointments.module#AppointmentsModule",
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "pages",
    component: SimpleLayoutComponent,
    data: {
      title: "Pages",
    },
    children: [
      {
        path: "",
        loadChildren: "./core/components/pages/pages.module#PagesModule",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
