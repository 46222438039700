import { Component, Input } from "@angular/core";
import { BsModalRef} from "ngx-bootstrap";

@Component({
  selector: "filler-block",
  template: `
  <div *ngIf="shouldShow" class="col-md-12">
  <div class="status-wrapper">
    <img class="status-icon" [src]="'./assets/images/' + getIcon" alt="">
    <div class="status-title">{{ defaultHeading }}</div>
    <div class="status-text">
      <p>{{ defaultText }}</p>
    </div>
  </div>
</div>
    `
})
export class FillerBlock {
    @Input() shouldShow: boolean = false;
    @Input() icon: string = 'empty';
    @Input() defaultText: string = '';
    @Input() defaultHeading: string = 'No Results!';


  modalRef: BsModalRef;

    get getIcon() {
        switch(this.icon) {
            case 'task':
                return 'no-result-jam.svg';
            case 'counsellor':
                return 'counsellor-success.svg';
            case 'empty':
                return 'empty-state.svg';    
            default:
                return 'empty-state.svg';
        }
    }

    constructor(
        ) { }
}
