import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class ApiRoutes {
  public url: string;
  private baseUrl = environment.server.baseUrl;
  private apiVersionUrl = environment.server.apiVersionPath;

  public default_error_message =
    "Something went wrong, don't worry our engineers are fixing it right now.";

  get getBaseUrl(): string {
    return this.baseUrl + this.apiVersionUrl;
  }

  getUpdateTokenUrl(userId) {
    return `${this.getBaseUrl}/users/${userId}/fcm-token`;
  }

  loginUrl() {
    return `${this.getBaseUrl}/login?includes=`;
  }
  get getLogoutUrl() {
    return `${this.getBaseUrl}/logout`;
  }

  getStudentInterviewSessoinsUrl(userId, interviewId) {
    return `${this.getBaseUrl}/students/${userId}/interviews/${interviewId}/interview_sessions`;
  }

  getStudentVisaSessoinsUrl(userId, visaId) {
    return `${this.getBaseUrl}/students/${userId}/visas/${visaId}/visa_sessions`;
  }

  updateInterviewResultUrl(userId, interviewId) {
    return `${this.getBaseUrl}/students/${userId}/interviews/${interviewId}`;
  }

  updateVisaResultUrl(userId, visaId) {
    return `${this.getBaseUrl}/students/${userId}/visas/${visaId}`;
  }

  getForgotPasswordUrl() {
    return `${this.getBaseUrl}/forgot-password`;
  }
  getStudentByIdUrl(id) {
    return `${this.getBaseUrl}/students/${id}`;
  }
  getApproveDetailsUrl(id) {
    return `${this.getBaseUrl}/students/${id}?includes=counsellors`;
  }
  getApproveDetailsMentorUrl(id) {
    return `${this.getBaseUrl}/students/${id}?includes=mentors`;
  }
  getEnrollmentFormUrl(id) {
    return `${this.getBaseUrl}/students/${id}?includes=counsellors`;
    //return `${this.getBaseUrl}/students/id/enrolment`;
  }
  getStudentShortlistedProgrammmesUrl(studentId) {
    return `${this.getBaseUrl}/students/${studentId}/programs/type/shortlisted`;
  }

  getCreateInterviewSessionsUrl(studentId, interviewId) {
    return `${this.getBaseUrl}/students/${studentId}/interviews/${interviewId}/interview_sessions`;
  }

  getUpdateInterviewSessionsUrl(studentId, interviewId, sessionId) {
    return `${this.getBaseUrl}/students/${studentId}/interviews/${interviewId}/interview_sessions/${sessionId}`;
  }

  getCreateVisaSessionsUrl(studentId, visaId) {
    return `${this.getBaseUrl}/students/${studentId}/visas/${visaId}/visa_sessions`;
  }

  getUpdateVisaSessionsUrl(studentId, visaId, sessionId) {
    return `${this.getBaseUrl}/students/${studentId}/visas/${visaId}/visa_sessions/${sessionId}`;
  }

  getStudentMyProgrammmesUrl(studentId) {
    return `${this.getBaseUrl}/students/${studentId}/programs/type/my`;
  }

  getAllCollegeProgramsUrl(studentId) {
    return `${this.getBaseUrl}/students/${studentId}/college_programs`;
  }

  getAllLocationsUrl(studentId) {
    return `${this.getBaseUrl}/students/${studentId}/locations`;
  }

  getAllProgramCategoriesUrl(studentId) {
    return `${this.getBaseUrl}/students/${studentId}/program_categories`;
  }

  // getCollegeDetailsUrl(studentId,collegeId){
  //     return `${this.getBaseUrl}/students/${studentId}/programs/${collegeId}`;
  // }

  getSelfShortlistedCollegeDetailsUrl(studentId, collegeId) {
    return `${this.getBaseUrl}/students/${studentId}/programs/${collegeId}`;
  }

  // getStudentMyProgrammmesUrl(studentId) {
  //   return `${this.getBaseUrl}/students/${studentId}/programs/type/my`;
  // }

  getCollegeDetailsUrl(studentId, collegeId) {
    return `${this.getBaseUrl}/students/${studentId}/programs/${collegeId}?includes=college_program,program_checklists,student,counsellors,program_documents`;
  }

  disapproveCollegeUrl(studentId, collegeId) {
    return `${this.getBaseUrl}/students/${studentId}/programs/${collegeId}/disapprove`
  }

  getCollegeProgramDocumentsUrl(studentId, collegeId) {
    return `${this.getBaseUrl}/students/${studentId}/programs/${collegeId}/college_program_documents`;
  }

  getDocumentDraftsUrl(studentId, collegeId, documentID) {
    return `${this.getBaseUrl}/students/${studentId}/programs/${collegeId}/documents/${documentID}/drafts`;
  }

  getPostDocumentDraftsUrl(studentId, collegeId, documentID) {
    return `${this.getBaseUrl}/students/${studentId}/programs/${collegeId}/documents/${documentID}/drafts`;
  }

  getExportChatsUrl(id, groupId) {
    return `${this.getBaseUrl}/users/${id}/conversations/${groupId}/download`;
  }

  // getCollegeAllDocumentsUrl(studentId, collegeId) {
  //   return `${this.getBaseUrl}/students/${studentId}/programs/${collegeId}/documents?includes=college_program_document,document_file_type,writer`;
  // }

  // getCollegeDocumentUrl(collegeID, documentID) {
  //   return `${this.getBaseUrl}/students/student-id/colleges/college-id/documents/document-id`;
  // }
  // getMarkUnmarkUrl(studentId, collegeID, documentID, draftID) {
  //   return `${this.getBaseUrl}/students/${studentId}/programs/${collegeID}/documents/${documentID}/drafts/${draftID}/mark`;
  // }
  // getScheduleSessionUrl(studentId, collegeID, documentID) {
  //   return `${this.getBaseUrl}/students/${studentId}/programs/${collegeID}/documents/${documentID}?includes=college_program_document,document_file_type,writer`;
  // }
  // getContactUrl(userId) {
  //   return `${this.getBaseUrl}/users/${userId}/enquiries`;
  // }
  // getAllCollegesUrl() {
  //   return `${this.getBaseUrl}/programmes`;
  // }
  // getAddMyCollegesUrl(studentId) {
  //   return `${this.getBaseUrl}/students/${studentId}/programs/my?includes=`;
  // }
  // getDeleteMyCollegeUrl(collegeId, studentId) {
  //   return `${this.getBaseUrl}/students/${studentId}/programs/my/${collegeId}`;
  // }
  // getNotificationsUrl(id) {
  //   return `${this.getBaseUrl}/users/${id}/notifications`;
  // }
  // getChatsUrl(id) {
  //   return `${this.getBaseUrl}/users/${id}/conversations`;
  // }
  // downloadDocument(docName) {
  //   return `${this.baseUrl}/conversations/files/${docName}`;
  // }

  // postEnrollmentFormUrl(id) {
  //   return `${this.getBaseUrl}/students/${id}/enrolment`;
  // }

  getEnrolmentDetails(id) {
    return `${this.getBaseUrl}/students/${id}?includes=enrolment`;
  }
  putEnrolmentDetails(id, studentName) {
    return `${this.getBaseUrl}/students/${id}/${studentName}/enrolment`;
  }
  getReadConversationUrl(id, groupId) {
    return `${this.getBaseUrl}/users/${id}/conversation_groups/${groupId}`;
  }

  getCollegeAllDocumentsUrl(studentId, collegeId) {
    return `${this.getBaseUrl}/students/${studentId}/programs/${collegeId}/documents?includes=college_program_document,document_file_type,writer`;
  }

  getCollegeDocumentUrl(collegeID, documentID) {
    return `${this.getBaseUrl}/students/student-id/colleges/college-id/documents/document-id`;
  }
  getMarkUnmarkUrl(studentId, collegeID, documentID, draftID) {
    return `${this.getBaseUrl}/students/${studentId}/programs/${collegeID}/documents/${documentID}/drafts/${draftID}/mark`;
  }
  getScheduleSessionUrl(studentId, collegeID, documentID) {
    return `${this.getBaseUrl}/students/${studentId}/programs/${collegeID}/documents/${documentID}?includes=college_program_document,document_file_type,writer`;
  }
  getContactUrl(userId) {
    return `${this.getBaseUrl}/users/${userId}/enquiries`;
  }
  getAllCollegesUrl() {
    return `${this.getBaseUrl}/colleges`;
  }
  getAllProgramsUrl() {
    return `${this.getBaseUrl}/programes`;
  }
  getAddMyCollegesUrl(studentId) {
    return `${this.getBaseUrl}/students/${studentId}/programs/my?includes=`;
  }
  getDraftWriterModal(studentId, programId, documentId, draftId) {
    return `${this.getBaseUrl}/students/${studentId}/programs/${programId}/documents/${documentId}/drafts/${draftId}`;
  }
  getDeleteMyCollegeUrl(collegeId, studentId) {
    return `${this.getBaseUrl}/students/${studentId}/programs/my/${collegeId}`;
  }
  getNotificationsUrl(id) {
    return `${this.getBaseUrl}/users/${id}/notifications`;
  }
  getChatsUrl(id) {
    return `${this.getBaseUrl}/users/${id}/conversations`;
  }
  downloadDocument(docName) {
    return `${this.baseUrl}/conversations/files/${docName}`;
  }

  postEnrollmentFormUrl(id) {
    return `${this.getBaseUrl}/students/${id}/enrolment`;
  }
  getRoadmapUrl(id) {
    return `${this.getBaseUrl}/students/${id}/roadmap`;
  }
  getUpdateRoadmapUrl(userId, taskId) {
    return `${this.getBaseUrl}/students/${userId}/roadmap/${taskId}`;
  }
  //=============================================================
  // Config
  //=============================================================

  getConfigDataUrl() {
    return `${this.getBaseUrl}/config`;
  }

  //=============================================================
  // roadmap tags
  //============================================================
  getRoadmapTagsUrl() {
    return `${this.getBaseUrl}/tags`;
  }

  //============================================================
  //   activity
  //============================================================
  getUpdateActivityUrl(userId, activityId) {
    return `${this.getBaseUrl}/students/${userId}/activity/${activityId}`;
  }

  getAllActivityUrl(userId) {
    return `${this.getBaseUrl}/students/${userId}/activity`;
  }

  getAddActivityUrl(userId, tagId) {
    return `${this.getBaseUrl}/students/${userId}/activity/${tagId}`;
  }

  getSetPasswordUrl() {
    return `${this.getBaseUrl}/set-password`;
  }

  getResetPasswordUrl() {
    return `${this.getBaseUrl}/reset-password`;
  }

  getAllAppointmentsUrl() {
    return `${this.getBaseUrl}/appointments`;
  }

  getCreateAppointmentUrl(userId) {
    return `${this.getBaseUrl}/students/${userId}/appointments`;
  }
}
