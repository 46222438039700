import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../environments/environment';
import { AngularFireDatabase } from 'angularfire2/database';
import * as moment from 'moment';
import { Helper } from './core/utilities/helpers';
import settings from './core/config/settings';

@Injectable()
export class UploadFileService {


  constructor(public db: AngularFireDatabase) { }

  message: string = '';
  groupId: string;
  user1: string;
  user2: string;
  encryptedName: any;
  type: any = 2;

  sendMessage(path, name){
    this.message = name;
    const timestamp = new Date().toLocaleString();
    const formattedTimestamp = Helper.formatTimestamp(timestamp);
    this.db.database.ref(`/conversations/${this.groupId}`).push({
      sender_id: this.user1,
      receiver_id: this.user2,
      message: this.message,
      timestamp: formattedTimestamp,
      // timestamp: moment().format('LLL'),
      type: this.type,
      document_name: path
    });
  }

  uploadfile(file, dataObject) {

    this.user1 = dataObject.user1;
    this.user2 = dataObject.user2;
    this.groupId = dataObject.groupId;

    const bucket = new S3(
      {
        accessKeyId: settings.aws.s3.accessKeyId,
        secretAccessKey: settings.aws.s3.secretAccessKey,
        region: 'ap-south-1'
      }
    );
    let now = new Date();
    let uniqueId = now.valueOf(); 
    this.encryptedName = uniqueId+file.name;
    const params = {
      Bucket: `${settings.aws.s3.bucket}/${settings.aws.s3.folder.chat}`,
      Key: this.encryptedName,
      Body: file
    };
    console.log('====================+>Params',params);
    
    bucket.upload(params, (err, data) => {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }

      console.log('Successfully uploaded file.', data);
      this.sendMessage(this.encryptedName, file.name);
      return data;
    });
  }

}