import {
  Component,
  TemplateRef,
  AfterViewChecked,
  AfterViewInit,
} from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";

@Component({
  selector: "relatable-links-modal",
  templateUrl: "./relatable-links.component.html",
})
export class RelatableLinksModal implements AfterViewInit {
  public loading: any = false;
  linksArray: any;
  // applicationMaterialLinksArray: any;
  collegeProgramDocuments: any;

  constructor(public modalRef: BsModalRef) {
    this._startLoading();
  }

  ngAfterViewInit() {
    this._stopLoading();
  }

  _startLoading() {
    this.loading = true;
  }
  _stopLoading() {
    this.loading = false;
  }
}
