import {Component, TemplateRef, EventEmitter, OnInit} from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { CustomValidator } from 'src/app/core/utilities/validator/custom-validator';
import { Helper } from 'src/app/core/utilities/helpers';
import { CollegeService } from 'src/app/core/services/college.service';
import * as moment from 'moment';

@Component({
  selector: 'interview-assistance-modal',
  templateUrl: './interview-assistance.component.html'
})
export class InterviewAssistanceModal implements OnInit {

  public items:any = [];
  collegeId: any;
  collegeType: any;
  public event: EventEmitter<any> = new EventEmitter();
  public interviewAssistanceForm: FormGroup;
  public loading: boolean = false;
  public submitted: boolean = false;
  public minDate: any = new Date();

  constructor(
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    private formBuilder: FormBuilder,
    public collegeService: CollegeService
    ) {
  }

  ngOnInit(){
    this._initForms();
    this._fetchInterviewModes();
  }

  _fetchInterviewModes(){
    this.items = this.collegeService.getInterviewModes();
  }

  _initForms() {
		this.interviewAssistanceForm = this.formBuilder.group({
      value: [3, Validators.required],
		  interview_details: [true, Validators.required],
			mode: ['', Validators.required],
			date: ['', Validators.required],
			interview_trainer_requested : [true, Validators.required]
    });
  }

  selectMode(event){
    this.interviewAssistanceForm.patchValue({

      mode: event.id,
  
    });
  }

  submit(){
    this.submitted = true;
    if(this.interviewAssistanceForm.invalid){
      return;
    }
    let status = Helper.cloneObj(this.interviewAssistanceForm.value, {
      date: moment(this.interviewAssistanceForm.value.date).format('L')
    });
    let formData;
    if(this.collegeType == 1){
      formData = {
        result: status
      }
    }else{
      formData = {
        status: status
      }
    }
    let params = {
      type: (this.collegeType == 1) ? 1 : 0
    }
    this._startLoading();
    this.collegeService.updateProgrameResult(formData, this.collegeId, params).subscribe( 
      res => {
        location.reload();
        this._stopLoading();
      }, 
      error => {
        console.log(error);
        this._stopLoading();
      });
  }

  triggerFailEvent(){
    this.event.emit({success: false});
    this.modalRef.hide();
  }

  triggerSuccessEvent(){
    let tempObject = {
      success: true
    };
    this.event.emit(tempObject);
    this.modalRef.hide();
  }

  _startLoading(){
    this.loading = true;
  }

  _stopLoading(){
    this.loading = false;
  }

}