import {Component, TemplateRef, EventEmitter, OnInit} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UserService } from '../../../services/user.services';
import { Toastr } from '../../../plugins/toastr/toastr';
import { InterviewAssistanceModal } from '../interview-assistance/interview-assistance.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Helper } from 'src/app/core/utilities/helpers';
import * as moment from 'moment';
import { CollegeService } from 'src/app/core/services/college.service';

@Component({
  selector: 'visa-counselling-modal',
  templateUrl: './visa-counselling.component.html',
  providers:[ Toastr ]
})
export class VisaCounsellingModal implements OnInit {
  
  public event: EventEmitter<any> = new EventEmitter();
  collegeId: any;
  collegeType: any;
  public disable: boolean = true;
  public error: boolean = false;
  public success: boolean = false;
  public loading: boolean = false;
  public submitted: boolean = false;
  public visaCounsellingForm: FormGroup;
  public minDate: any = new Date();

  constructor(
    public userService: UserService, 
    public toastr: Toastr, 
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    public collegeService: CollegeService
    ) {}


  ngOnInit(){
    this._initForms();
  }


  _initForms() {
		this.visaCounsellingForm = this.formBuilder.group({
			value: [1, Validators.required],
			admit_details: [true, Validators.required],
			date: ['', Validators.required],
      scholarship_details : ['', Validators.required],
      scholarship_negotiation: [false, Validators.required],
      loan_details : ['', Validators.required],
      visa_counsellor_requested : [false, Validators.required],
    });
  }


  submit(){
    this.submitted = true;
    if(this.visaCounsellingForm.invalid){
      return;
    }

    let formData = Helper.cloneObj({
      date: moment(this.visaCounsellingForm.value.date).format('L'),
      scholarship:{
        details: this.visaCounsellingForm.value.scholarship_details,
        negotiation: this.visaCounsellingForm.value.scholarship_negotiation
      },
      loan:{
        details: this.visaCounsellingForm.value.loan_details
      }
    }, this.visaCounsellingForm.value);
    if(this.collegeType == 1){
      formData = {
        result: formData
      }
    }else{
      formData = {
        status: formData
      }
    }
    let params = {
      type:(this.collegeType == 1) ? 1 : 0
    }
    this._startLoading();
    this.collegeService.updateProgrameResult(formData, this.collegeId, params).subscribe( 
      res => {
        // location.reload();
        this.triggerSuccessEvent();
        this._stopLoading();
      }, 
      error => {
        console.log(error);
        this._stopLoading();
      });
  }

  triggerFailEvent(){
    this.event.emit({success: false});
    this.modalRef.hide();
  }

  triggerSuccessEvent(){
    let tempObject = {
      success: true
    };
    this.event.emit(tempObject);
    this.modalRef.hide();
  }

  _startLoading() {
    this.loading = true;
  }
  
  _stopLoading() {
    this.loading = false;
  }

}