import { FormControl } from '@angular/forms';
import { Helper } from '../helpers';

export class CustomValidator {

	static isEmptyInputValue(value) {
		return value == null || (typeof value === 'string' && value.length === 0) ||
			(
				Object.prototype.toString.call(value) === '[object Array]' &&
				value.length === 0
			);
	}

	static notEmpty(control: FormControl) {
		return (CustomValidator.isEmptyInputValue(control.value)) ?
			{ notEmpty: true } :
			null;
	}

	// Valdiates email
	static email(control: FormControl) {
		let EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!control.value) {
			return null;
		}

		return EMAIL_REGEXP.test(control.value) ? null : {
			email: true
		};
	}

	// Valdiates exact length
	static exactLength = (length: number) => {
		return (control: FormControl) => {
			if (CustomValidator.isEmptyInputValue(control.value)) {
				return null;
			}

			let actualLength = typeof control.value === 'string' ? control.value.length : 0;
			return control.value.length === length ?
				null :
				{
					'exactLength': {
						'requiredLength': length,
						'actualLength': actualLength
					}
				};
		}
	}

	// Validating Float number
	static number = (type: string) => {
		return (control: FormControl) => {
			if (CustomValidator.isEmptyInputValue(control.value)) {
				return null;
			}
			
			let number = typeof control.value === 'string' ? control.value.length : 0;
			let validatorMessage: string = '';

			switch (type) {
				case 'float':
					return (Helper.isFloat(control.value)) ?
						null :
						{
							'number': {
								'numType': type,
							}
						};

				case 'floatInt':
					return (Helper.isFloat(parseFloat(control.value)) || Helper.isInt(parseInt(control.value))) ?
						null :
						{
							'number': {
								'numType': 'number',
							}
						};
			}
		}
	}

	// Valdiates less than length
	static lessThenLength = (length: number) => {
		return (control: FormControl) => {
			if (CustomValidator.isEmptyInputValue(control.value)) {
				return null;
			}

			let actualLength = typeof control.value === 'string' ? control.value.length : 0;
			return control.value.length < length ?
				null :
				{
					'lessThenLength': {
						'requiredLength': length,
						'actualLength': actualLength
					}
				};
		}
	}

}
