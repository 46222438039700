import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiRoutes } from "../config/api-routes";
import "rxjs/Rx";
import { Router } from "@angular/router";
import { Storage } from "../utilities/storage";
import { Helper } from "../utilities/helpers";

@Injectable()
export class UserService {
  public collegeDetails: any = null;
  public currentUser: any;
  public currentOwner: any;
  public consellorConfirmedStatus: boolean = false;
  public mentorConfirmedStatus: boolean = false;

  constructor(
    private http: HttpClient,
    private apiRoutes: ApiRoutes,
    private router: Router,
    public storage: Storage
  ) {
    this.currentUser = this.storage.get("currentUser");
    this.currentOwner = this.storage.get("currentStudent");
  }

  // getStudentDetails() {
  //   return this.http
  //     .get(this.apiRoutes.getStudentByIdUrl(this.currentUser.id))
  //     .map((res: any) => {
  //       this.storage.set(
  //         "currentUser",
  //         Helper.cloneObj(this.currentUser, {
  //           status: res.data.owner.status,
  //         })
  //       );
  //       return res;
  //     });
  // }
  setConfig(configData) {
    this.storage.set("config", configData);
  }

  getConfigData() {
    return this.http.get(this.apiRoutes.getConfigDataUrl()).map((res: any) => {
      return res;
    });
  }

  //  get currentUser(){
  //     return this.storage.get('currentUser');
  //  }

  //  get currentOwner(){
  //     return this.storage.get('currentStudent');
  //  }

  getStudentDetails(params = {}) {
    this.currentUser = this.storage.get("currentUser");
    let url =
      this.apiRoutes.getStudentByIdUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    console.log("===========++<>");
    return this.http.get(url).map((res: any) => {
      this.storage.set(
        "currentUser",
        Helper.cloneObj(this.currentUser, {
          status: res.data.owner.status,
          studentDetailStatus: res.data.owner.student_detail_status,
        })
      );
      return res;
    });
  }

  approveDetails() {
    return this.http
      .put(this.apiRoutes.getApproveDetailsUrl(this.currentUser.id), {
        student_status: 2,
      })
      .map((res: any) => {
        this.storage.set(
          "currentUser",
          Helper.cloneObj(this.currentUser, {
            status: res.data.status,
            studentDetailStatus: res.data.owner.student_detail_status,
          })
        );
        return res;
      });
  }

  postEnrollmentForm(formDetails) {
    return this.http
      .post(
        this.apiRoutes.postEnrollmentFormUrl(this.currentUser.id),
        formDetails
      )
      .map((res: any) => {
        return res;
      });
  }

  acknowledgeCounsellorAssignment() {
    console.log("counsellor confrim status", this.consellorConfirmedStatus);
    return this.http
      .put(this.apiRoutes.getApproveDetailsUrl(this.currentUser.id), {
        student_status: 4,
      })
      .map((res: any) => {
        this.storage.set(
          "currentUser",
          Helper.cloneObj(this.currentUser, {
            status: res.data.owner.status,
            studentDetailStatus: res.data.owner.student_detail_status,
          })
        );
        return res;
      });
  }

  acknowledgeMentorAssignment() {
    console.log("mentor confrim status", this.consellorConfirmedStatus);
    return this.http
      .put(this.apiRoutes.getApproveDetailsMentorUrl(this.currentUser.id), {
        student_status: 6,
      })
      .map((res: any) => {
        this.storage.set(
          "currentUser",
          Helper.cloneObj(this.currentUser, {
            status: res.data.owner.status,
          })
        );
        return res;
      });
  }

  readConversation(groupId) {
    return this.http
      .put(
        this.apiRoutes.getReadConversationUrl(this.currentUser.slug, groupId),
        {}
      )
      .map((res: any) => {
        return res;
      });
  }

  exportChat(groupId) {
    return this.http
      .get(this.apiRoutes.getExportChatsUrl(this.currentUser.slug, groupId))
      .map((res: any) => {
        return res;
      });
  }

  setRootPage() {
    let params = {
      includes: "course_type,counsellors,mentors",
    };
    // if (this.currentOwner.enrolment === null) {
    //   this.router.navigate(["enrollment-form"]);
    //   return;
    // }
    let url =
      this.apiRoutes.getStudentByIdUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    this.http.get(url).subscribe((res: any) => {
      if (res.data.owner.student_detail_status < 3) {
        if (res.data.owner.student_detail_status === 1) {
          this.router.navigate(["confirm-details"]);
          return;
        } else if (res.data.owner.student_detail_status === 2) {
          this.router.navigate(["enrollment-form"]);
          return;
        }
      }
      switch (res.data.owner.status) {
        case 1:
          this.router.navigate(["confirm-details"]);
          return;
        case 2:
          this.router.navigate(["enrollment-form"]);
          return;
        case 3:
          this.router.navigate(["enrollment-submitted"]);
          return;
        case 4:
          let counsellor = this.storage.set(
            "counsellors",
            res.data.owner.counsellors[0]
          );
          const studentDetailStatus = res.data.owner.student_detail_status;
          // if (res.data.owner.student_detail_status < 3) {
          //   if (res.data.owner.student_detail_status === 1) {
          //     this.router.navigate(["confirm-details"]);
          //     return;
          //   } else if (res.data.owner.student_detail_status === 2) {
          //     this.router.navigate(["enrollment-form"]);
          //     return;
          //   }
          // }
          // this.router.navigate(["enrollment-form"]);

          // if (res.data.owner.enrolment === null) {
          //   // this.router.navigate(["confirm-details"]);
          //   this.router.navigate(["enrollment-form"]);
          // } else this.router.navigate(["counsellor-assigned"]);

          // console.log(counsellor);

          return;
        case 5:
          if (this.router.url.includes("colleges/my-colleges")) {
            this.router.navigate(["colleges/my-colleges"]);
          } else {
            this.router.navigate(["colleges/shortlisted-colleges"]);
          }
          return;
        case 6:
          // if (res.data.owner.student_detail_status < 3) {
          //   if (res.data.owner.student_detail_status === 1) {
          //     this.router.navigate(["confirm-details"]);
          //     return;
          //   } else if (res.data.owner.student_detail_status === 2) {
          //     this.router.navigate(["enrollment-form"]);
          //     return;
          //   }
          // }
          this.router.navigate(["mentor-assigned"]);
          if (this.mentorConfirmedStatus) {
            if (this.router.url.includes("colleges/my-colleges")) {
              this.router.navigate(["colleges/my-colleges"]);
            } else {
              this.router.navigate(["colleges/shortlisted-colleges"]);
            }
            this.consellorConfirmedStatus = false;
          }
          return;
        case 7:
          // if (res.data.owner.student_detail_status < 3) {
          //   if (res.data.owner.student_detail_status === 1) {
          //     this.router.navigate(["confirm-details"]);
          //     return;
          //   } else if (res.data.owner.student_detail_status === 2) {
          //     this.router.navigate(["enrollment-form"]);
          //     return;
          //   }
          // }
          if (this.router.url.includes("colleges/my-colleges")) {
            this.router.navigate(["colleges/my-colleges"]);
          } else {
            this.router.navigate(["colleges/shortlisted-colleges"]);
          }
          return;
        case 8:
          // if (res.data.owner.student_detail_status < 3) {
          //   if (res.data.owner.student_detail_status === 1) {
          //     this.router.navigate(["confirm-details"]);
          //     return;
          //   } else if (res.data.owner.student_detail_status === 2) {
          //     this.router.navigate(["enrollment-form"]);
          //     return;
          //   }
          // }
          if (this.router.url.includes("colleges/my-colleges")) {
            this.router.navigate(["colleges/my-colleges"]);
          } else {
            this.router.navigate(["colleges/shortlisted-colleges"]);
          }
          return;
        case 9:
          // if (res.data.owner.student_detail_status < 3) {
          //   if (res.data.owner.student_detail_status === 1) {
          //     this.router.navigate(["confirm-details"]);
          //     return;
          //   } else if (res.data.owner.student_detail_status === 2) {
          //     this.router.navigate(["enrollment-form"]);
          //     return;
          //   }
          // }
          if (this.router.url.includes("colleges/my-colleges")) {
            this.router.navigate(["colleges/my-colleges"]);
          } else {
            this.router.navigate(["colleges/shortlisted-colleges"]);
          }
          return;

        default:
          this.router.navigate(["pages/login"]);
          return;
      }
    });
  }

  // exportChat(groupId) {
  //   return this.http
  //     .get(this.apiRoutes.getExportChatsUrl(this.currentUser.slug, groupId))
  //     .map((res: any) => {
  //       return res;
  //     });
  // }

  // setRootPage() {
  //   this.http
  //     .get(this.apiRoutes.getStudentByIdUrl(this.currentUser.id))
  //     .subscribe((res: any) => {
  //       switch (res.data.owner.status) {
  //         case 1:
  //           this.router.navigate(["confirm-details"]);
  //           return;
  //         case 2:
  //           this.router.navigate(["enrollment-form"]);
  //           return;
  //         case 3:
  //           this.router.navigate(["enrollment-submitted"]);
  //           return;
  //         case 4:
  //           let counsellor = this.storage.set(
  //             "counsellors",
  //             res.data.owner.counsellors[0]
  //           );
  //           console.log(counsellor);
  //           this.router.navigate(["counsellor-assigned"]);
  //           return;
  //         case 5:
  //           if (this.router.url.includes("colleges/my-colleges")) {
  //             this.router.navigate(["colleges/my-colleges"]);
  //           } else {
  //             this.router.navigate(["colleges/shortlisted-colleges"]);
  //           }
  //           return;

  //         default:
  //           this.router.navigate(["pages/login"]);
  //           return;
  //       }
  //     });
  // }

  contact(enquiry) {
    return this.http
      .post(this.apiRoutes.getContactUrl(this.currentUser.id), {
        content: enquiry,
      })
      .map((res: any) => {
        return res;
      });
  }

  getNotifications(params = {}) {
    let url =
      this.apiRoutes.getNotificationsUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  readNotifications(notificationIds) {
    let url = this.apiRoutes.getNotificationsUrl(this.currentUser.id);
    return this.http.put(url, notificationIds).map((res: any) => {
      return res;
    });
  }

  getChats(params = {}) {
    let url =
      this.apiRoutes.getChatsUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getEnrollmentData() {
    let url = this.apiRoutes.getEnrolmentDetails(this.currentUser.id);
    return this.http.get(url);
  }

  putEnrollmentDetails(editEnrollmentData) {
    let url = this.apiRoutes.putEnrolmentDetails(
      this.currentUser.id,
      this.currentUser.slug
    );
    console.log(editEnrollmentData);
    return this.http.put(url, editEnrollmentData);
    // yahan pe return karne ke liye body + header bhi dena padega
  }

  // getChats(params = {}){
  //     let url = this.apiRoutes.getChatsUrl(this.currentUser.id) + Helper.getQueryParamsString(params);
  //     return this.http.get(url)
  //     .map((res:any) => {
  //         return res;
  //     });
  // }

  getRoadmap(params = {}) {
    let url =
      this.apiRoutes.getRoadmapUrl(this.currentOwner.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  addRoadmap(formData) {
    return this.http
      .post(this.apiRoutes.getRoadmapUrl(this.currentOwner.id), formData)
      .map((res: any) => {
        return res;
      });
  }

  updateRoadmap(taskId, formData) {
    return this.http
      .put(
        this.apiRoutes.getUpdateRoadmapUrl(this.currentOwner.id, taskId),
        formData
      )
      .map((res: any) => {
        return res;
      });
  }

  deleteRoadmap(taskId) {
    return this.http
      .delete(this.apiRoutes.getUpdateRoadmapUrl(this.currentOwner.id, taskId))
      .map((res: any) => {
        return res;
      });
  }

  getRoadmapTags() {
    return this.http.get(this.apiRoutes.getRoadmapTagsUrl()).map((res: any) => {
      return res;
    });
  }

  updateActivity(activityId, activityData) {
    return this.http
      .put(
        this.apiRoutes.getUpdateActivityUrl(this.currentOwner.id, activityId),
        activityData
      )
      .map((res: any) => {
        return res;
      });
  }
  getAllActivity() {
    console.log(
      "Id of student to fetch recommendations",
      this.storage.get("currentStudent").id
    );
    return this.http
      .get(
        this.apiRoutes.getAllActivityUrl(this.storage.get("currentStudent").id)
      )
      .map((res: any) => {
        return res;
      });
  }
  getAddActivity(tagId, data) {
    return this.http
      .post(this.apiRoutes.getAddActivityUrl(this.currentOwner.id, tagId), data)
      .map((res: any) => {
        return res;
      });
  }

  getAllAppointments(params) {
    let url =
      this.apiRoutes.getAllAppointmentsUrl() +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCreateAppointment(data) {
    let url = this.apiRoutes.getCreateAppointmentUrl(this.currentUser.id);
    return this.http.post(url, data).map((res: any) => {
      return res;
    });
  }
}
