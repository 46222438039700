import { NgModule } 							              from '@angular/core';
import { CommonModule }                     	  from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { BsDropdownModule, TooltipModule, ModalModule, AccordionModule, TabsModule, BsDatepickerModule, TimepickerModule, TypeaheadModule } from 'ngx-bootstrap';

import { Storage } from './core/utilities/storage';
import { ControlMessages } from './core/utilities/validator/control-messages';
import { ToastrService } from 'ngx-toastr';
import { Toastr } from './core/plugins/toastr/toastr';
import { FillerBlock } from './core/directives/filler-block.directive';
import { SelectModule } from 'ng2-select';


@NgModule({
    imports: [
      CommonModule,
      BsDropdownModule.forRoot(),
      TooltipModule.forRoot(),
      ModalModule.forRoot(),
      AccordionModule.forRoot(),
      TabsModule.forRoot(),
      BsDatepickerModule.forRoot(),
      TimepickerModule.forRoot(),
      TypeaheadModule.forRoot(),
      FormsModule,
      SelectModule
    ],
    declarations: [
      ControlMessages,
      FillerBlock
    ],
    exports: [
      CommonModule,
      BsDropdownModule,
      TooltipModule,
      ModalModule,
      ControlMessages,
      FillerBlock,
      AccordionModule,
      TabsModule,
      BsDatepickerModule,
      TimepickerModule,
      TypeaheadModule,
      FormsModule,
      SelectModule
  	],
  	providers: [
      Storage,
      Toastr,
      ToastrService,
    ]
})
export class SharedModule {
}
