import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiRoutes } from "../config/api-routes";
import { Storage } from "../utilities/storage";
import "rxjs/Rx";
import { Helper } from "../utilities/helpers";
import * as _ from "lodash";

@Injectable()
export class CollegeService {
  public collegeDetails: any = null;

  constructor(
    private http: HttpClient,
    private apiRoutes: ApiRoutes,
    public storage: Storage
  ) {}

  get currentUser() {
    return this.storage.get("currentUser");
  }

  get currentStudent() {
    return this.storage.get("currentStudent");
  }

  getInterviewModes() {
    let config = this.storage.get("config");
    let items = [];
    _.mapKeys(config.private.interview_modes, function (value, key) {
      let tempObject = {
        id: value.value,
        text: value.text,
      };
      items.push(tempObject);
    });
    return items;
  }

  getInterviewSessions(interviewId, params = {}) {
    let url =
      this.apiRoutes.getStudentInterviewSessoinsUrl(
        this.currentStudent.id,
        interviewId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getVisaSessions(visaId, params = {}) {
    let url =
      this.apiRoutes.getStudentVisaSessoinsUrl(this.currentStudent.id, visaId) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getMyColleges(params = {}) {
    let url =
      this.apiRoutes.getStudentMyProgrammmesUrl(this.currentStudent.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getAllCollegePrograms(params = {}) {
    let url =
      this.apiRoutes.getAllCollegeProgramsUrl(this.currentStudent.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getAllLocations(params = {}) {
    let url =
      this.apiRoutes.getAllLocationsUrl(this.currentStudent.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getAllProgramCategories(params = {}) {
    let url =
      this.apiRoutes.getAllProgramCategoriesUrl(this.currentStudent.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCollegeDocuments(collegeId) {
    return this.http
      .get(
        this.apiRoutes.getCollegeAllDocumentsUrl(
          this.currentStudent.id,
          collegeId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getShortListedColleges(params = {}) {
    let url =
      this.apiRoutes.getStudentShortlistedProgrammmesUrl(
        this.currentStudent.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  createInterviewSession(interviewId, formData, params) {
    let url =
      this.apiRoutes.getCreateInterviewSessionsUrl(
        this.currentStudent.id,
        interviewId
      ) + Helper.getQueryParamsString(params);
    return this.http.post(url, formData).map((res: any) => {
      return res;
    });
  }

  updateInterviewSession(interviewId, sessionId, formData, params = {}) {
    let url =
      this.apiRoutes.getUpdateInterviewSessionsUrl(
        this.currentStudent.id,
        interviewId,
        sessionId
      ) + Helper.getQueryParamsString(params);
    return this.http.patch(url, formData).map((res: any) => {
      return res;
    });
  }

  updateInterviewResult(interviewId, formData, params = {}) {
    let url =
      this.apiRoutes.updateInterviewResultUrl(
        this.currentStudent.id,
        interviewId
      ) + Helper.getQueryParamsString(params);
    return this.http.patch(url, formData).map((res: any) => {
      return res;
    });
  }

  updateVisaResult(visaId, formData, params = {}) {
    let url =
      this.apiRoutes.updateVisaResultUrl(this.currentStudent.id, visaId) +
      Helper.getQueryParamsString(params);
    return this.http.patch(url, formData).map((res: any) => {
      return res;
    });
  }

  createVisaSession(visaId, formData, params) {
    let url =
      this.apiRoutes.getCreateVisaSessionsUrl(this.currentStudent.id, visaId) +
      Helper.getQueryParamsString(params);
    return this.http.post(url, formData).map((res: any) => {
      return res;
    });
  }

  updateVisaSession(visaId, sessionId, formData, params = {}) {
    let url =
      this.apiRoutes.getUpdateVisaSessionsUrl(
        this.currentStudent.id,
        visaId,
        sessionId
      ) + Helper.getQueryParamsString(params);
    return this.http.patch(url, formData).map((res: any) => {
      return res;
    });
  }
  getCollegeProgramsDocuments(programid) {
    let url = this.apiRoutes.getCollegeProgramDocumentsUrl(
      this.currentStudent.id,
      programid
    );
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCollegesDetails(id, params = {}, forcefully = false) {
    console.log("===========>12", Helper.getQueryParamsString(params));
    if (forcefully) {
      let url =
        this.apiRoutes.getCollegeDetailsUrl(this.currentStudent.id, id) +
        Helper.getQueryParamsString(params);
      return this.http.get(url).map((res: any) => {
        this.collegeDetails = res;
        return res;
      });
    } else {
      if (this.collegeDetails) {
        // if (this.collegeDetails.id == id) {
        //     return Observable.of(this.collegeDetails);
        // }else{
        //     return this.http.get(this.apiRoutes.getCollegeDetailsUrl(id))
        //     .map((res:any) => {
        //     this.collegeDetails = res;
        //     return res;
        //     });
        // }
        return Observable.of(this.collegeDetails);
      } else {
        let url =
          this.apiRoutes.getCollegeDetailsUrl(this.currentStudent.id, id) +
          Helper.getQueryParamsString(params);
        return this.http.get(url).map((res: any) => {
          this.collegeDetails = res;
          return res;
        });
      }
    }
  }

  getSelfShortlistedCollegesDetails(id, params = {}, forcefully = false) {
    if (forcefully) {
      let url =
        this.apiRoutes.getSelfShortlistedCollegeDetailsUrl(
          this.currentStudent.id,
          id
        ) + Helper.getQueryParamsString(params);
      return this.http.get(url).map((res: any) => {
        this.collegeDetails = res;
        return res;
      });
    } else {
      if (this.collegeDetails) {
        // if (this.collegeDetails.id == id) {
        //     return Observable.of(this.collegeDetails);
        // }else{
        //     return this.http.get(this.apiRoutes.getCollegeDetailsUrl(id))
        //     .map((res:any) => {
        //     this.collegeDetails = res;
        //     return res;
        //     });
        // }
        return Observable.of(this.collegeDetails);
      } else {
        let url =
          this.apiRoutes.getSelfShortlistedCollegeDetailsUrl(
            this.currentStudent.id,
            id
          ) + Helper.getQueryParamsString(params);
        return this.http.get(url).map((res: any) => {
          this.collegeDetails = res;
          return res;
        });
      }
    }
  }

  getCurrentCollege() {
    return Observable.of(this.collegeDetails);
  }

  getDocumentDetails(collegeID, documentID) {
    return this.http
      .get(this.apiRoutes.getCollegeDocumentUrl(collegeID, documentID))
      .map((res: any) => {
        return res;
      });
  }

  markUnmarkDraft(action, documentID, collegeID, draftID) {
    if (action == "Unmark") {
      action = false;
    } else {
      action = true;
    }
    return this.http
      .patch(
        this.apiRoutes.getMarkUnmarkUrl(
          this.currentStudent.id,
          collegeID,
          documentID,
          draftID
        ),
        { is_final: action }
      )
      .map((res: any) => {
        return res;
      });
  }

  scheduleSessions(data, collegeID, documentID) {
    return this.http
      .put(
        this.apiRoutes.getScheduleSessionUrl(
          this.currentStudent.id,
          collegeID,
          documentID
        ),
        { document_sessions: data }
      )
      .map((res: any) => {
        return res;
      });
  }

  submitUserApplicationDetails(data, collegeID) {
    return this.http
      .put(
        this.apiRoutes.getCollegeDetailsUrl(this.currentStudent.id, collegeID),
        { credentials: data }
      )
      .map((res: any) => {
        return res;
      });
  }

  approveColleges(id) {
    return this.http
      .put(this.apiRoutes.getCollegeDetailsUrl(this.currentStudent.id, id), {
        is_approved_by_student: true,
      })
      .map((res: any) => {
        return res;
      });
  }

  disapproveColleges(id) {
    return this.http
    .patch(this.apiRoutes.disapproveCollegeUrl(this.currentStudent.id, id), {
      is_approved_by_student: false,
    })
    .map((res: any) => {
      return res;
    });
  }

  submitCompletedTasks(data, id, params) {
    let url =
      this.apiRoutes.getCollegeDetailsUrl(this.currentStudent.id, id) +
      Helper.getQueryParamsString(params);
    return this.http.put(url, { program_checklists: data }).map((res: any) => {
      return res;
    });
  }

  updateProgrameResult(formData, id, params = {}) {
    let url =
      this.apiRoutes.getCollegeDetailsUrl(this.currentStudent.id, id) +
      Helper.getQueryParamsString(params);
    return this.http.put(url, formData).map((res: any) => {
      return res;
    });
  }

  updateSelfShortlistedCollegeProgrameResult(formData, id, params = {}) {
    let url =
      this.apiRoutes.getSelfShortlistedCollegeDetailsUrl(
        this.currentStudent.id,
        id
      ) + Helper.getQueryParamsString(params);
    return this.http.put(url, formData).map((res: any) => {
      return res;
    });
  }

  getDocumentDraft(collegeID, documentID, params = {}) {
    let url =
      this.apiRoutes.getDocumentDraftsUrl(
        this.currentStudent.id,
        collegeID,
        documentID
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getAllColleges() {
    return this.http.get(this.apiRoutes.getAllCollegesUrl()).map((res: any) => {
      return res;
    });
  }

  getAllPrograms() {
    return this.http.get(this.apiRoutes.getAllProgramsUrl()).map((res: any) => {
      return res;
    });
  }

  skipSession(collegeId, documentId) {
    return this.http
      .put(
        this.apiRoutes.getScheduleSessionUrl(
          this.currentStudent.id,
          collegeId,
          documentId
        ),
        { status: 1 }
      )
      .map((res: any) => {
        return res;
      });
  }

  addMyColleges(collegeProgram) {
    return this.http
      .post(
        this.apiRoutes.getAddMyCollegesUrl(this.currentStudent.id),
        collegeProgram
      )
      .map((res: any) => {
        return res;
      });
  }

  updateStudentsWriterModal(programId, draftId, documentId, form) {
    let url = this.apiRoutes.getDraftWriterModal(
      this.currentStudent.id,
      programId,
      documentId,
      draftId
    );
    return this.http.put(url, form).map((res: any) => {
      return res;
    });
  }

  removeMyColleges(collegeId) {
    return this.http
      .delete(
        this.apiRoutes.getDeleteMyCollegeUrl(collegeId, this.currentStudent.id)
      )
      .map((res: any) => {
        return res;
      });
  }

  downloadDocument(docName) {
    return this.http
      .get(this.apiRoutes.downloadDocument(docName))
      .map((res: any) => {
        return res;
      });
  }
}
