import {Component, TemplateRef} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UserService } from '../../../services/user.services';
import { Toastr } from '../../../plugins/toastr/toastr';

@Component({
  selector: 'contact-modal',
  templateUrl: './contact.component.html',
  providers:[ Toastr ]
})
export class ContactModal {
  // title: string;
  // closeBtnName: string;
  // list: any[] = [];

  public disable: boolean = true;
  public error: boolean = false;
  public success: boolean = false;
  public loading: boolean = false;
  query: string = '';
  successMsg: String =  'Thank You, We Will Contact You Shortly.';

  constructor(public userService: UserService, public toastr: Toastr, public modalRef: BsModalRef) {}

  closeModal(modalRef: any) {
    modalRef.hide();
  }

  valuechange(e){
    if (e.target.value != "") {
      if(e.target.value.length > 255){
        this.error = true;
        this.disable = true;
      }else{
        this.error = false;
        this.disable = false;
      }
    }else{
      this.disable = true;
    }
  }

  submit(){
    this.query = this.query.trim();
    if(this.query == '')
    {
      this.toastr.showWarning('In order to raise a query, you need to write any issue first.');
      return;
    }
    this._startLoading();
     this.userService.contact(this.query).subscribe(
       res => {
         console.log(res);
         this.query = '';
         this.toastr.showSuccess('We\'ll get back to you shortly.');
         this.modalRef.hide();
         this._stopLoading(); 
       },
       error => {
          console.log(error); 
          this.query = '';
          this._stopLoading();
       });
  }

  _startLoading() {
    this.loading = true;
  }
  
  _stopLoading() {
    this.loading = false;
  }

}