import {
  Component,
  TemplateRef,
  OnInit,
  NgModule,
  ViewChild,
} from "@angular/core";
// import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { UserService } from "../../../services/user.services";
import { ModalOptions, TypeaheadMatch } from "ngx-bootstrap";
import { Toastr } from "../../../plugins/toastr/toastr";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { CustomValidator } from "../../../utilities/validator/custom-validator";
import { Helper } from "../../../utilities/helpers";
import * as moment from "moment";
import { SelectComponent } from "ng2-select";
import { Observable } from "rxjs";

// needed http method for dummy data fetch
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "add-roadmap-modal",
  templateUrl: "./add-roadmap.component.html",
  providers: [Toastr],
})
export class AddRoadmapModal implements OnInit {
  // @ViewChild("SelectId") public select: SelectComponent;
  // @ViewChild("SelectId1") public selectCategory: SelectComponent;
  public loading: boolean = false;
  submitted: boolean = false;
  roadmapForm: FormGroup;
  public minDate: any = new Date();
  public tasks: any = [];
  public editByStudent: any;
  public initialState: any;
  public taskId: any;
  public particularTask: any = [];
  public timer: any = 0;
  public tags: Observable<Array<any>>;
  public tag_id = new Set();
  public defaultTag: any = [];
  // public defaultTagArr: any = [];

  constructor(
    public userService: UserService,
    public toastr: Toastr,
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    public options: ModalOptions
  ) {
    this.initialState = this.options.initialState;
    this.taskId = this.initialState.taskId;
    this.editByStudent = this.initialState.editByStudent;
    if (this.editByStudent) {
      this.getRoadmap();
    }
    console.log("Inside modal ", this.taskId, this.editByStudent);
  }

  ngOnInit() {
    this._initRoadmapForm();
    this._fetchTagsFromServer();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.roadmapForm.controls;
  }

  _initRoadmapForm() {
    this.roadmapForm = this.formBuilder.group({
      title: ["", [Validators.required, CustomValidator.lessThenLength(254)]],
      description: [
        "",
        [Validators.required, CustomValidator.lessThenLength(254)],
      ],
      class: [""],
      priority: ["", Validators.required],
      monthly_check_in: [0],
      tags: [""],
      deadline: ["", [Validators.required]],
    });
  }

  getRoadmap(params = {}) {
    params = Helper.cloneObj(
      {
        includes: "created_by,tags",
      },
      params
    );
    this._startLoading();
    this.userService.getRoadmap(params).subscribe(
      (res) => {
        console.log("RoadMap from modal", res);
        this.tasks = [];
        res.data.map((task) => {
          this.tasks.push(Helper.cloneObj(task, this._getOtherDetails(task)));
        });
        // this.tasks = res.data;
        if (this.editByStudent) {
          this.extractTasktoEdit();
          this._setDefaultTag();
        }
        this._stopLoading();
      },
      (error) => {
        this._stopLoading();
      }
    );
  }
  _getOtherDetails(task) {
    let date = task.deadline.split("-");
    let tempObject = { deadline: date[1] + "/" + date[2] + "/" + date[0] };

    return tempObject;
  }

  submit() {
    this.roadmapForm.patchValue({
      tags: Array.from(this.tag_id.values()),
    });
    this.submitted = true;
    console.log("Final form for submission", this.roadmapForm.value);

    if (this.editByStudent) {
      if (this.roadmapForm.invalid) {
        console.log("error");
        return;
      }
      console.log("submitted edited form", this.roadmapForm.value);
      this.updateRoadmap(this.roadmapForm.value);
    } else {
      if (this.roadmapForm.invalid) {
        console.log("error");
        return;
      }
      console.log("final add roadmap submit button", this.roadmapForm.value);

      this.finalSubmit(this.roadmapForm.value);
    }
  }

  updateRoadmap(dataObject) {
    dataObject.deadline = Helper.changeDateFormat(dataObject);
    console.log(dataObject);
    // dataObject.deadline = moment(dataObject.deadline).format("L");

    console.log("==================+>", dataObject.deadline);
    this._startLoading();
    this.userService.updateRoadmap(this.taskId, dataObject).subscribe(
      (res) => {
        this.toastr.showSuccess("Successfully updated task");
        this._stopLoading();
        this.modalRef.hide();
        console.log(res);
      },
      (error) => {
        this._stopLoading();
        console.log(error);
      }
    );
  }

  finalSubmit(dataObject) {
    dataObject.deadline = moment(dataObject.deadline).format("L");
    console.log("==================+>", dataObject);
    this._startLoading();
    this.userService.addRoadmap(dataObject).subscribe(
      (res) => {
        this.toastr.showSuccess("Successfully added new task");

        this._stopLoading();
        this.modalRef.hide();
        console.log(res);
      },
      (error) => {
        this._stopLoading();
        console.log(error);
      }
    );
  }

  extractTasktoEdit() {
    this.particularTask = Helper.searchObjById(this.tasks, this.taskId);
    this.particularTask = this.particularTask[0];
    console.log(this.particularTask.deadline, "Particular Task ");
  }

  _startLoading() {
    this.loading = true;
  }

  _stopLoading() {
    this.loading = false;
  }
  //======================================================================
  // Tags selection functions
  //======================================================================

  _refreshTagValue(value: any): void {}
  _selectedTag(value: any): void {
    this.tag_id.clear();
    this.tag_id.add(value.id);
    console.log(this.tag_id);
    console.log(this.roadmapForm.value);
  }

  // _removedTag(value: any): void {
  //   console.log(value);
  //   this.tag_id.delete(value.id);
  //   console.log(this.tag_id);
  // }

  _typedTag(value: any): void {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      let obj = {
        query: value,
      };
      this._fetchTagsFromServer(obj);
    }, 2000);
  }

  _setDefaultTag() {
    let assignedTag = {};
    // for api fetching.

    for (let i = 0; i < this.particularTask.tags.length; i++) {
      assignedTag = {
        id: Helper.getObjProp(this.particularTask.tags[i], "id"),
        text: Helper.getObjProp(this.particularTask.tags[i], "name"),
      };
      this.tag_id.add(Helper.getObjProp(this.particularTask.tags[i], "id"));
      this.defaultTag = [...this.defaultTag, assignedTag];
    }

    // console.log("Set deafault function started working",this.defaultTag);
  }

  _fetchTagsFromServer(paramsObj: Object = {}) {
    this.userService.getRoadmapTags().subscribe((res: any) => {
      console.log("Res dummy data", res);
      let data = Helper.getObjProp(res, "data");
      let obj = [];
      for (let i = 0; i < data.length; i++) {
        obj.push({
          id: data[i].id,

          text: `${data[i].name}`,
        });
      }
      this.tags = Observable.of(obj).map((res) => res);
      console.log(this.tags, "Tags");
    });
  }
}
