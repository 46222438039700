import {Component, TemplateRef, OnInit} from '@angular/core';
// import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { CollegeService } from '../../../services/college.service';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { Toastr } from '../../../plugins/toastr/toastr';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { CustomValidator } from '../../../utilities/validator/custom-validator';
import { Helper } from 'src/app/core/utilities/helpers';
import { Observable } from 'rxjs';

@Component({
  selector: 'add-college-modal',
  templateUrl: './add-college.component.html',
  providers: [ Toastr ]
})
export class AddCollegeModal implements OnInit {
  public loading: boolean = false;
  public tempColleges: any = [];
  public colleges: any = [];
  selectedProgram: any;
  public error: boolean = false;
  public errorMsg: any;
  selectedOption: any;
  selectedCollege: any;
  submitted: boolean = false;
  newCollegeProgramForm: FormGroup;
  collegeProgramForm: FormGroup;
  public success: boolean = false;
  public checked: boolean = false;
  public collegePrograms: any = [];
  public timer: any = 0;
  public locations: any = [];
  public programCategories: any = [];

  constructor(
    public collegeService: CollegeService,
    public toastr: Toastr, 
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    ) {
  }

  ngOnInit(){
    this._initForms();
    this._fetchCollegeProgramFromServer();
    this._fetchLocationFromServer();
    this._fetchProgramCategoryFromServer();
  }


// =================================

// College Program Methods

// =================================



_selectedCollegeProgram(value: any): void {
  
	this.collegeProgramForm.patchValue({

		college_program_id: value.id,

	});
  
	console.log(this.collegeProgramForm);

}



_removedCollegeProgram(value: any): void {

	this.collegeProgramForm.patchValue({

		college_program_id: '',

	});

	console.log(this.collegeProgramForm);

}



_typedCollegeProgram(value: any): void {

	clearTimeout(this.timer);



	this.timer = setTimeout(() => {

			let obj = {

					query: value

			};

			this._fetchCollegeProgramFromServer(obj);

	}, 2000);

}



_refreshCollegeProgramValue(value: any): void { }



_fetchCollegeProgramFromServer(paramsObj: Object = {}) {
  this._startLoading();

	paramsObj = Helper.cloneObj(paramsObj, {

			limit: 100,

	});

	this.collegeService.getAllCollegePrograms(paramsObj)

			.subscribe(

					(res) => {

							let data = Helper.getObjProp(res, 'data');

							let obj = [];

							for (let i = 0; i < data.length; i++) {

									if(data[i].meta_data.type == 0){
                    obj.push({

											id: data[i].id,

											text: `${data[i].meta_data.name}`

									});
                  }

							}

              this.collegePrograms = obj;
              this._stopLoading();
              console.log(this.collegePrograms);
					},

					(error) => {

							Helper.processErrorResponse(error, this.toastr);

							// this.collegePrograms = Observable.never();
							this.collegePrograms = [];

					}

			);

  }


// =================================

// Locations Methods

// =================================



_selectedLocation(value: any): void {
  
	this.newCollegeProgramForm.patchValue({

		location_id: value.id,

	});
  
	console.log(this.newCollegeProgramForm);

}



_removedLocation(value: any): void {

	this.newCollegeProgramForm.patchValue({

		location_id: '',

	});

	console.log(this.newCollegeProgramForm);

}



_typedLocation(value: any): void {

	clearTimeout(this.timer);



	this.timer = setTimeout(() => {

			let obj = {

					query: value

			};

			this._fetchLocationFromServer(obj);

	}, 2000);

}



_refreshLocationValue(value: any): void { }



_fetchLocationFromServer(paramsObj: Object = {}) {

	paramsObj = Helper.cloneObj(paramsObj, {

			limit: 100,

	});

	this.collegeService.getAllLocations(paramsObj)

			.subscribe(

					(res) => {

							let data = Helper.getObjProp(res, 'data');

							let obj = [];

							for (let i = 0; i < data.length; i++) {

									obj.push({

											id: data[i].id,

											text: `${data[i].name}`

									});

							}

							this.locations = obj;
              console.log(this.locations);
					},

					(error) => {

							Helper.processErrorResponse(error, this.toastr);

							// this.locations = Observable.never();
							this.locations = [];

					}

			);

  }


// =================================

// Program Category Methods

// =================================



_selectedProgramCategory(value: any): void {
  
	this.newCollegeProgramForm.patchValue({

		program_category_id: value.id,

	});
  
	console.log(this.newCollegeProgramForm);

}



_removedProgramCategory(value: any): void {

	this.newCollegeProgramForm.patchValue({

		program_category_id: '',

	});

	console.log(this.newCollegeProgramForm);

}



_typedProgramCategory(value: any): void {

	clearTimeout(this.timer);



	this.timer = setTimeout(() => {

			let obj = {

					query: value

			};

			this._fetchProgramCategoryFromServer(obj);

	}, 2000);

}



_refreshProgramCategoryValue(value: any): void { }



_fetchProgramCategoryFromServer(paramsObj: Object = {}) {

	paramsObj = Helper.cloneObj(paramsObj, {

			limit: 100,

	});

	this.collegeService.getAllProgramCategories(paramsObj)

			.subscribe(

					(res) => {

							let data = Helper.getObjProp(res, 'data');

							let obj = [];

							for (let i = 0; i < data.length; i++) {

									obj.push({

											id: data[i].id,

											text: `${data[i].name}`

									});

							}

							this.programCategories = obj;
              console.log(this.programCategories);
					},

					(error) => {

							Helper.processErrorResponse(error, this.toastr);

							// this.programCategories = Observable.never();
							this.programCategories = [];

					}

			);

  }


  _initForms(){
    this.collegeProgramForm = this.formBuilder.group({
      college_program_id: ['', [
                Validators.required
              ]]
    });
    this.newCollegeProgramForm = this.formBuilder.group({
      college_name: ['', [
                Validators.required,
                CustomValidator.lessThenLength(254)
              ]],
      college_description: ['', [
                Validators.required,
                CustomValidator.lessThenLength(254)
              ]],
      program_name: ['', [
                Validators.required,
                CustomValidator.lessThenLength(254)
              ]],
      program_description: ['', [
                Validators.required,
                CustomValidator.lessThenLength(254)
              ]],
      location_id: ['', [
                Validators.required
              ]],
      program_category_id: ['', [
                Validators.required
              ]]
    });
  }

  onSelectProgram(event: TypeaheadMatch): void {
    this.selectedOption = event.item;
    this.colleges.forEach(element => {
      if(element.programName === event.value){
        this.tempColleges.push(element);
      }
    });
    this.colleges = this.tempColleges;
    this.tempColleges = [];
  }

  onSelectCollege(event: TypeaheadMatch): void {
    this.selectedOption = event.item;
    this.colleges.forEach(element => {
      if(element.collegeName === event.value){
        this.tempColleges.push(element);
      }
    });
    this.colleges = this.tempColleges;
    this.tempColleges = [];
  }

  submitCollegeProgram(){
    this.submitted = true;
    if(this.collegeProgramForm.invalid){
      console.log('error')
      return;
    }
    this._startLoading();
      this.collegeService.addMyColleges(this.collegeProgramForm.value).subscribe(
        res => {
          this.toastr.showSuccess('Successfully added college')
          this._stopLoading();
          this.modalRef.hide();
          this.success = true;
          console.log(res);
        },
        error => {
          this.success = false;
          this._stopLoading();
           console.log(error); 
        });
  }

  submitNewCollegeProgram(){
    this.submitted = true;
    if(this.newCollegeProgramForm.invalid){
      console.log('error')
      return;
    }
    this._startLoading();
      let formData = Helper.cloneObj({
        custom: true
      },this.newCollegeProgramForm.value)
      this.collegeService.addMyColleges(formData).subscribe(
        res => {
          this.toastr.showSuccess('Successfully added college')
          this._stopLoading();
          this.modalRef.hide();
          this.success = true;
          console.log(res);
        },
        error => {
          this.success = false;
          this._stopLoading();
           console.log(error); 
        });
  }

  check(e){
    if(e.target.checked){
      this.checked = true;
    }else{
      this.checked = false;
    }
  }



  _startLoading() {
    this.loading = true;
  }
  
  _stopLoading() {
    this.loading = false;
  }

}
