import { Component, OnInit, ViewChild, EventEmitter } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService, ModalOptions } from "ngx-bootstrap";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { UserService } from "src/app/core/services/user.services";
import { Toastr } from "src/app/core/plugins/toastr/toastr";
import { Helper } from "src/app/core/utilities/helpers";
import { parse, stringify } from "querystring";

@Component({
  selector: "app-slot-selection",
  templateUrl: "./comment-add.component.html",
  styleUrls: ["./comment-add.component.css"],
  providers: [UserService],
})
export class CommentAddModal implements OnInit {
  // @ViewChild("dp1") datePicker: any;
  public commentForm: FormGroup;
  public initialState: any;
  public commentsForm: FormGroup;
  public visibleFields: any = [];
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public modalRef: BsModalRef,
    public options: ModalOptions,
    public formBuilder: FormBuilder,
    public userService: UserService,
    public toastr: Toastr
  ) {
    this.initialState = this.options.initialState;
    // console.log("time", this.initialState);
  }
  _initCommentHandler() {
    this.commentForm = this.formBuilder.group({
      comment: [this.initialState.comment],
    });
    console.log(this.commentForm.value);
  }

  onCommentHandler() {
    let tempObject = Helper.cloneObj(
      // {
      //   success: true,

      // },
      this.commentForm.value
    );
    console.log("Successfully Done", tempObject);
    this.event.emit(tempObject);

    this.modalRef.hide();
  }

  ngOnInit() {
    this._initCommentHandler();
  }
}
