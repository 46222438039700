import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { SharedModule } from "./shared.module";
import { AngularFireModule } from "angularfire2";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { UploadFileService } from "./upload-file.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptorService } from "./core/services/jwt.interceptor.service";

import { environment } from "../environments/environment";

// Routing Module
import { AppRoutingModule } from "./app.routing";

// Layouts
import { FullLayoutComponent } from "./core/components/layouts/full-layout.component";
import { SimpleLayoutComponent } from "./core/components/layouts/simple-layout.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { UploadModal } from "./core/components/modals/upload/upload.component";
import { UploadDraftsModal } from "./core/components/modals/upload-drafts/upload-drafts.component";
import { AuthenticationService } from "./core/services/authentication.service";
import { ApiRoutes } from "./core/config/api-routes";
import { UserService } from "./core/services/user.services";
import { CollegeService } from "./core/services/college.service";
import { ContactModal } from "./core/components/modals/contact/contact.component";
import { AddCollegeModal } from "./core/components/modals/add-college/add-college.component";
import { Toastr } from "./core/plugins/toastr/toastr";
import { ErrorPageComponent } from "./core/components/error-page/error-page.component";
import { FileSelectDirective, FileUploadModule } from "ng2-file-upload";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { AngularFireAuthModule } from "angularfire2/auth";
import { AngularFireMessagingModule } from "angularfire2/messaging";
import { MessagingService } from "./core/services/messaging.service";
import { TutorialModal } from "./core/components/modals/tutorial/tutorial.component";
import { InterviewAssistanceModal } from "./core/components/modals/interview-assistance/interview-assistance.component";
import { VisaCounsellingModal } from "./core/components/modals/visa-counselling/visa-counselling.component";
import { AddRoadmapModal } from "./core/components/modals/add-roadmap/add-roadmap.component";
import { ViewFeedbackModal } from "./core/components/modals/view-feedback/view-feedback.component";
import { TelemachyModule } from "telemachy";
import { RelatableLinksModal } from "./core/components/modals/relatble-links/relatable-links.component";
import { CommentAddModal } from "./core/components/modals/comment-add/comment-add.component";

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    SimpleLayoutComponent,
    UploadModal,
    UploadDraftsModal,
    ContactModal,
    AddRoadmapModal,
    TutorialModal,
    AddCollegeModal,
    InterviewAssistanceModal,
    ErrorPageComponent,
    VisaCounsellingModal,
    ViewFeedbackModal,
    RelatableLinksModal,
    CommentAddModal,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true,
      preventDuplicates: true,
    }), // ToastrModule added
    SharedModule,
    BrowserModule,
    FileUploadModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule, // for database
    TelemachyModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    UploadFileService,
    ApiRoutes,
    Toastr,
    AuthenticationService,
    CollegeService,
    MessagingService,
    UserService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
  ],
  entryComponents: [
    UploadModal,
    UploadDraftsModal,
    ContactModal,
    TutorialModal,
    AddRoadmapModal,
    AddCollegeModal,
    InterviewAssistanceModal,
    VisaCounsellingModal,
    ViewFeedbackModal,
    RelatableLinksModal,
    CommentAddModal,
  ],
  exports: [FormsModule, ReactiveFormsModule],
})
export class AppModule {}
