import {Component, TemplateRef} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'tutorial-modal',
  templateUrl: './tutorial.component.html'
})
export class TutorialModal {
 
  constructor(public modalRef: BsModalRef) {}

}