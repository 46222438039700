export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDImnPdGXKb3hPks_j_JzdkwD-fl-4B3o0",
    authDomain: "jams-53082.firebaseapp.com",
    databaseURL: "https://jams-53082.firebaseio.com",
    projectId: "jams-53082",
    storageBucket: "jams-53082.appspot.com",
    messagingSenderId: "741360284907",
    appId: "1:741360284907:web:ca228cabac3fa910",
  },
  temp_doc_path: "",
  server: {
    baseUrl: "https://admissions.jamboreeeducation.com/api",
    apiVersionPath: "/api/v1",
  },
};
