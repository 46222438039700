import { Injectable, Component } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Storage } from '../utilities/storage';
import { Toastr } from '../plugins/toastr/toastr';
import { AuthenticationService } from './authentication.service';
import { Router } from '../../../../node_modules/@angular/router';
import { Helper } from '../utilities/helpers';


@Injectable()
export class JwtInterceptorService implements HttpInterceptor {

    constructor(
        public storage: Storage,
        public toastr: Toastr,
        public auth: AuthenticationService,
        private router: Router
    ) {  }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.storage.get('currentUser');
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }
 
        return next.handle(request)
         .map((event: HttpEvent<any>) => {
            return event;
          })
          .catch((err: any, caught) => {
            if (err instanceof HttpErrorResponse) {
                

              switch (err.status) {
                  case 0:
                    this.toastr.showError('Oops something went wrong');
                    console.log('===================+>Error Page Executed');
                    this.router.navigate(['error']);
                      break;

                  case 401:
                    this.toastr.showError(err.error.message);
                    this.auth.logout();
                    this.router.navigate(['pages/login']);
                      break;

                //   case 500:
                //      this.toastr.showError('Oops Something Went Wrong');
                //       break;

                  case 403:
                    // if(err.error.code == 403002 || err.error.code == 403001){
                    //     this.toastr.showError(err.error.message);
                    //     this.storage.clear();
                    //     this.router.navigate(['/pages/login']);
                    //     // location.reload();
                    // }
              
                  default:
                     Helper.processErrorResponse(err, this.toastr);
                      break;
              }
              return Observable.throw(err);
            }
          });
    }
}