import { Component } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { MessagingService } from './core/services/messaging.service';
import { AuthenticationService } from './core/services/authentication.service';
import { UserService } from './core/services/user.services';

@Component({
	selector: 'body',
	template: '<telemachy-tour></telemachy-tour><router-outlet></router-outlet>',
	providers: [
		UserService
	]
})
export class AppComponent {

	public message: any;

	constructor(
		public userService: UserService,
		public messagingService: MessagingService,
		public authService: AuthenticationService
		) {
		setTheme('bs4');
		this.userService.getConfigData().subscribe(
			res => {
			  this.userService.setConfig(res.data);
			}
		  );
		const user = this.authService.authUser;
		if(user){
			this.messagingService.requestPermission(user.id)
			this.messagingService.receiveMessage()
			this.message = this.messagingService.currentMessage
			}
	}
}
