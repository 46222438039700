import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  AfterViewChecked,
} from "@angular/core";
// import { Router } from '@angular/router';

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { AuthenticationService } from "../../services/authentication.service";
import { Router } from "@angular/router";
import { UserService } from "../../services/user.services";
import { InterCommService } from "../../services/inter-comm.service";
import * as moment from "moment";
import { Storage } from "../../utilities/storage";
import { ContactModal } from "../modals/contact/contact.component";
import { Helper } from "../../utilities/helpers";
import { Toastr } from "../../plugins/toastr/toastr";
import { AngularFireDatabase } from "angularfire2/database";
import settings from "../../config/settings";
import {
  TourStep,
  ElementTourStep,
  HTMLTourStep,
  TelemachyService,
  HasGuidedTour,
  YoutubeTourStep,
} from "telemachy";
// import { ContactModal } from '../modals/contact/contact.component';

@Component({
  selector: "app-dashboard",
  templateUrl: "./full-layout.component.html",
  providers: [InterCommService],
})
export class FullLayoutComponent
  implements OnInit, AfterViewChecked, HasGuidedTour
{
  notifications: Array<{ url: any; name: string; time: any }> = [];
  modalRef: BsModalRef;
  public currentUser: any;
  public token: any;
  loading: boolean = false;
  public unReadNotifications: any = [];
  showTabs: boolean = false;
  public unReadNotificationClass: any = {
    box: "pulse-glow-repeat-0-2",
    text: "",
  };
  public MAX_NOTIFICATION_LIMIT: number = 99;
  public date: any = new Date();
  public year: any;
  public unread_msg_count: any = 0;

  constructor(
    private modalService: BsModalService,
    public storage: Storage,
    public authService: AuthenticationService,
    private router: Router,
    public userService: UserService,
    private intercomm: InterCommService,
    public toastr: Toastr,
    public db: AngularFireDatabase,
    public telemachyService: TelemachyService
  ) {
    this.isCounsellorAssigned();
    this.checkIfCounsellorAssigned();
    this.getCurrentUser();
    this.year = moment(this.date).format("YYYY");
    this.unreadMessageCount();
  }

  getCurrentUser() {
    this.currentUser = this.storage.get("currentUser");
    this.currentUser.profilePhoto =
      "url(" + this.currentUser.profilePhoto + ")";
    this.token = this.currentUser.token;
    this._fetchUnreadNotificationCount();
  }

  _fetchUnreadNotificationCount() {
    this.db
      .object(`/users/${this.currentUser.id}`)
      .valueChanges()
      .subscribe((res: any) => {
        this.unReadNotificationClass.text = "";
        this.unReadNotifications.length = res && res.unread_notifications_count;
        this.getNotifications();
        setTimeout(() => {
          if (Helper.isEmpty(this.unReadNotificationClass.box)) {
            this.unReadNotificationClass.box = "pulse-glow-repeat-0-2";
          } else {
            this.unReadNotificationClass.text = "animated flash";
          }
        }, 100);
      });
  }

  ngAfterViewChecked() {
    let authUser = this.authService.authUser;
    if (authUser) {
      if (this.router.url.includes("shortlisted-colleges")) {
        this.checkTutorial();
      }
    }
  }

  checkTutorial() {
    let authUser = this.authService.authUser;
    let oldSignees = this.storage.get("oldSignees");
    if (oldSignees) {
      if (!oldSignees.userIds.includes(authUser.id)) {
        this.showTutorial();
      }
    } else {
      this.showTutorial();
    }
  }

  showTutorial() {
    console.log("Showing Tutorial");
    this.telemachyService.startTour(this);
    this.hideTutorial();
  }

  getTour(): TourStep[] {
    const tourSteps = [
      new ElementTourStep(
        "#step1",
        "In this tab you will see all your colleges."
      ),
      new ElementTourStep(
        "#step2",
        "This tab lists the tasks and activities you will do during the application process. You and your counselor/mentor can add tasks and track your progress"
      ),
    ];
    if (
      this.currentUser.packageId === 10 ||
      this.currentUser.packageId === 11 ||
      this.currentUser.packageId === 12
    ) {
      tourSteps.push(
        new ElementTourStep(
          "#step8",
          "This tab contains various activities you might do as part of your profile building. Activities added with a target date can also be seen in the roadmap tab."
        )
      );
    }
    return [
      ...tourSteps,
      new ElementTourStep(
        "#step3",
        "This tab consist of chat app, you can send messages and files in this tab to all of professionals assigned to you."
      ),
      new ElementTourStep(
        "#step4",
        "In case, you need any help, you can contact us through this button."
      ),
      new ElementTourStep(
        "#step5",
        "All of your new notification will appear here by clicking this button."
      ),
      new ElementTourStep(
        "#step6",
        "In this sub-tab you will see, a list of colleges shortlisted for you by our counsellor"
      ),
      new ElementTourStep(
        "#step7",
        "This tab is for you to let us know of other colleges you have applied too, we will surely can help you."
      ),
      new YoutubeTourStep(`pyfTVVbmwwA`),
    ];
  }

  hideTutorial() {
    console.log("Hiding Tutorial");
    let authUser = this.authService.authUser;
    let oldSignees = this.storage.get("oldSignees");
    if (oldSignees) {
      oldSignees.userIds.push(authUser.id);
      this.storage.set("oldSignees", {
        userIds: oldSignees.userIds,
      });
    } else {
      this.storage.set("oldSignees", {
        userIds: [authUser.id],
      });
    }
  }

  // Get all unread mesages
  unreadMessageCount() {
    let params = {
      includes: "conversation_group,sender,role",
    };
    this.userService.getChats(params).subscribe(
      (res) => {
        res.data.map((msg) => {
          if (msg.conversation_group.is_unread) {
            this.unread_msg_count++;
          }
        });
        if (this.unread_msg_count > 99) {
          this.unread_msg_count = "99+";
        }
      },
      (err) => {
        console.log("Error", err);
      }
    );
    console.log("Message Count", this.unread_msg_count);
  }

  _onNotificationClicked(event: any) {
    this.unReadNotificationClass.box = "";
  }

  checkIfCounsellorAssigned() {
    this.intercomm.checkIfCounsellorAssigned
      .asObservable()
      .subscribe((value: any) => {
        this._startLoading();
        this.isCounsellorAssigned();
      });
  }

  isCounsellorAssigned() {
    let params = {
      includes: "course_type,counsellors",
    };
    this.userService.getStudentDetails(params).subscribe(
      (res) => {
        if (
          res.data.owner.status >= 3 &&
          res.data.owner.student_detail_status > 2
        ) {
          this.showTabs = true;
          this.getNotifications();
        }
        this._stopLoading();
      },
      (error) => {
        this._stopLoading();
        console.log(error);
      }
    );
  }

  openModal() {
    this.modalRef = this.modalService.show(ContactModal);
    // this.modalRef.content.closeBtnName = 'Close';
  }

  ngOnInit(): void {
    this.isCounsellorAssigned();
  }

  getNotifications(params = {}) {
    params = Helper.cloneObj(
      {
        limit: 100,
      },
      params
    );
    this.userService.getNotifications(params).subscribe(
      (res) => {
        this.notifications = [];
        let message;
        res.data.forEach((element) => {
          let url = this.getUrl(element);
          let time = moment(element.created_at).fromNow();
          message = element.meaningful_details.message;
          let tempObject = {
            id: element.id,
            url: url,
            is_read: element.is_read,
            name: element.meaningful_details.title,
            message: message,
            time: time,
            type: element.type,
            content: message,
            meta_data: element.metadata,
          };
          this.notifications.push(tempObject);
        });

        this.unReadNotifications = res.data.filter(this.checkRead);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  checkRead(notification) {
    return notification.is_read == false;
  }
  getUrl(element) {
    let allowedProfilePhoto = [1, 2, 3, 4, 5, 6, 10, 11, 19, 21];
    if (allowedProfilePhoto.includes(element.type)) {
      return element.meaningful_details.image;
    } else {
      return "assets/images/check-icon.svg";
    }
  }

  goToParticularNotification(element) {
    if (!element.is_read) {
      let notificationId = {
        notifications_read: [element.id],
      };
      this.userService.readNotifications(notificationId).subscribe();
    }
    let dbNotificationTypes = settings.databaseNotifications.types;
    let url = null;
    let notificationObj = null;
    switch (element.type) {
      case 1:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "newCounsellorAssignedToStudent"
        );
        url = Helper.getObjProp(notificationObj, "url");
        break;
      case 2:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "counsellorReassignedToStudent"
        );
        url = Helper.getObjProp(notificationObj, "url");
        break;
      case 3:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "newWriterAssignedToStudent"
        );
        url = Helper.getTranslatedStr(
          Helper.getObjProp(notificationObj, "url"),
          {
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
          }
        );
        this.router.navigate([url], {
          queryParams: {
            documentId: element.meta_data.studentProgramDocument.id,
          },
        });
        return;
      case 4:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "writerReassignedToStudent"
        );
        url = Helper.getTranslatedStr(
          Helper.getObjProp(notificationObj, "url"),
          {
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
          }
        );
        this.router.navigate([url], {
          queryParams: {
            documentId: element.meta_data.studentProgramDocument.id,
          },
        });
        return;
      case 6:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "writerUploadedDraft"
        );
        url = Helper.getTranslatedStr(
          Helper.getObjProp(notificationObj, "url"),
          {
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
          }
        );
        break;
      case 8:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "collegesShortlisted"
        );
        url = Helper.getObjProp(notificationObj, "url");
        break;
      case 9:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "collegeApproved"
        );
        console.log("College Approved ===", element);
        url = Helper.getTranslatedStr(
          Helper.getObjProp(notificationObj, "url"),
          {
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
          }
        );
        break;
      case 11:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "writerUnmarkedDraftFinal"
        );
        url = Helper.getTranslatedStr(
          Helper.getObjProp(notificationObj, "url"),
          {
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
          }
        );
        break;
      case 12:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "collegeDisapproved"
        );
        url = Helper.getTranslatedStr(
          Helper.getObjProp(notificationObj, "url"),
          {
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
          }
        );
        break;

      case 19:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "newinterviewTrainerAssignedToStudent"
        );
        if (element.meta_data.collegeProgram.meta_data.type == 0) {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "url"),
            {
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        } else {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "urlWithQuery"),
            {
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        }
        break;

      case 21:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "interviewTrainerReassignedToStudent"
        );
        if (element.meta_data.collegeProgram.meta_data.type == 0) {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "url"),
            {
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        } else {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "urlWithQuery"),
            {
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        }
        break;

      case 22:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "newVisaCounsellorAssignedToStudent"
        );
        if (element.meta_data.collegeProgram.meta_data.type == 0) {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "url"),
            {
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        } else {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "urlWithQuery"),
            {
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        }
        break;

      case 23:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "visaCounsellorReassignedToStudent"
        );
        if (element.meta_data.collegeProgram.meta_data.type == 0) {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "url"),
            {
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        } else {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "urlWithQuery"),
            {
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        }
        break;

      //newMentorAssignedToStudent
      case 30:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "newMentorAssignedToStudent"
        );
        url = Helper.getObjProp(notificationObj, "url");
        break;

      //mentorReassignedToStudent
      case 31:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "mentorReassignedToStudent"
        );
        url = Helper.getObjProp(notificationObj, "url");
        break;

      //collegeDisapprovedByMentor
      case 33:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "collegeDisapprovedByMentor"
        );
        url = Helper.getObjProp(notificationObj, "url");
        break;

      //collegeApprovedByMentor
      case 34:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "collegeApprovedByMentor"
        );
        url = Helper.getObjProp(notificationObj, "url");
        break;

      //newAppointmentCreatedByCounsellor
      case 38:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "newAppointmentCreatedByCounsellor"
        );
        url = Helper.getObjProp(notificationObj, "url");
        break;

      //appointmentUpdatedByCounsellor
      case 40:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "appointmentUpdatedByCounsellor"
        );
        url = Helper.getObjProp(notificationObj, "url");
        break;

      default:
        url = "colleges/shortlisted-colleges";
        break;
    }

    this.router.navigate([url]);
  }

  goToNotifications() {
    this.router.navigate(["notifications"]);
    // this.readNotification(notification.id);
    // if(notification.type === '1'){
    //   this.readNotification(notification.id);
    //   this.router.navigate(['chat']);
    // }else{
    //   this.readNotification(notification.id);
    //   this.router.navigate(['individual-college/true/'+ notification.meta_data.college_id +'/single-document/'+ notification.meta_data.document_id]);
    // }
  }

  // readNotification(notificationId){
  //   this.userService.readNotifications(notificationId).subscribe(
  //     res => {
  //       this.getNotifications();
  //       console.log(res);
  //     },
  //     error => {
  //        console.log(error);
  //     });
  // }

  logout() {
    this.authService
      .logout()
      .finally(() => {
        let localObjects = settings.localStorage.objectNames;
        localObjects.forEach((element) => {
          if (element != "oldSignees") {
            this.storage.remove(element);
          }
        });
      })
      .subscribe((res) => {
        if (res) {
          this.router.navigate(["/pages/login"]);
          this.toastr.showSuccess("Successfully logged out.");
          // location.reload();
        }
      });
  }

  _startLoading() {
    this.loading = true;
  }

  _stopLoading() {
    this.loading = false;
  }
}
