import { environment } from "src/environments/environment";
export default {
  mime: {
    type: ["image/png", "image/jpg", "image/jpeg"],
  },

  aws: {
    s3: {
      //   accessKeyId: "AKIAUM2KULF2LZCRNV2D",
      //   secretAccessKey: "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",
      accessKeyId: environment.aws.access_key_id,
      secretAccessKey: environment.aws.secret_access_key,
      bucket: "admissions.jamboreeeducation.com",
      folder: {
        chat: "uploads/documents/chat",
      },
    },
  },

  bulkUpload: {
    mime: {
      type: ["text/csv"],
    },
  },

  databaseNotifications: {
    types: {
      newCounsellorAssignedToStudent: {
        url: "chat",
      },
      counsellorReassignedToStudent: {
        url: "chat",
      },
      newWriterAssignedToStudent: {
        url: "individual-college/{ collegeId }/documents",
      },
      writerReassignedToStudent: {
        url: "individual-college/{ collegeId }/documents",
      },
      writerUploadedDraft: {
        url: "individual-college/{ collegeId }/documents",
      },
      collegesShortlisted: {
        url: "colleges/shortlisted-colleges",
      },
      collegeApproved: {
        url: "individual-college/{ collegeId }/information",
      },
      writerUnmarkedDraftFinal: {
        url: "individual-college/{ collegeId }/documents",
      },
      collegeDisapproved: {
        url: "individual-college/{ collegeId }/information",
      },
      newinterviewTrainerAssignedToStudent: {
        url: "individual-college/{ collegeId }/interview-assistance",
        urlWithQuery:
          "individual-college/{ collegeId }/interview-assistance?collegeType=1",
      },
      interviewTrainerReassignedToStudent: {
        url: "individual-college/{ collegeId }/interview-assistance",
        urlWithQuery:
          "individual-college/{ collegeId }/interview-assistance?collegeType=1",
      },
      newVisaCounsellorAssignedToStudent: {
        url: "individual-college/{ collegeId }/interview-assistance",
        urlWithQuery:
          "individual-college/{ collegeId }/visa-counselling?collegeType=1",
      },
      visaCounsellorReassignedToStudent: {
        url: "individual-college/{ collegeId }/visa-counselling",
        urlWithQuery:
          "individual-college/{ collegeId }/visa-counselling?collegeType=1",
      },

      // 30 newMentorAssignedToStudent:
      newMentorAssignedToStudent: {
        url: "colleges/shortlisted-colleges",
      },
      // 31 mentorReassignedToStudent
      mentorReassignedToStudent: {
        url: "colleges/shortlisted-colleges",
      },
      // 33 collegeDisapprovedByMentor
      collegeDisapprovedByMentor: {
        url: "colleges/shortlisted-colleges",
      },

      // 34 collegeApprovedByMentor
      collegeApprovedByMentor: {
        url: "colleges/shortlisted-colleges",
      },
      // 38  newAppointmentCreatedByCounsellor
      newAppointmentCreatedByCounsellor: {
        url: "appointments",
      },

      // 40 appointmentUpdatedByCounsellor

      appointmentUpdatedByCounsellor: {
        url: "appointments",
      },
    },
  },

  localStorage: {
    objectNames: [
      "currentUser",
      "currentStudent",
      "studentFcmToken",
      "oldSignees",
    ],
  },
};
